<template>
  <div class="section-main section-main-100">
    <em></em>
    <v-card
      class="mx-auto"
      max-width="500"
    >
      <v-form
        class="loginForm pa-10 text-center"
        @submit.prevent="submit"
      >
        <div class="pt-4 pb-10 text-center">
          <h4>Введите ваш адрес электронной почты для получения нового пароля</h4>
        </div>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="pb-1 pt-1"
            >
              <v-text-field
                v-model="email"
                :error-messages="checkErrors('email')"
                label="Адрес электронной почты"
                @input="$v.email.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn
            color="blue"
            class="mr-4 white--text"
            @click="submit"
            :loading="isLoading"
          >
            Получить пароль
          </v-btn>
        </v-card-actions>
        <AuthSnackBar
          :value="snackbar"
          text="На ваш email отправлен новый пароль"
        />
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'
import validationErrors from '@/components/mixins/validationErrors'
import loading from '@/components/mixins/loading'
import AuthSnackBar from '@/components/layouts/Auth/AuthSnackBar'

export default {
  components: { AuthSnackBar },
  mixins: [validationErrors, loading],
  name: 'RecoveryPassword',
  data: () => ({
    snackbar: false,
    email: ''
  }),
  validations: {
    email: {
      email,
      required
    }
  },
  methods: {
    async submit () {
      if (!this.isLoading) {
        this.isLoading = true
        this.$v.$touch()
        await this.checkPending()
        if (!this.$v.$anyError) {
          let response = await this.$store.dispatch('auth/resetPassword', this.email)
          if (response) {
            this.snackbar = true
          }
        }
        this.isLoading = false
      }
      return true
    }
  }
}
</script>

<style>

</style>

<style scoped>
</style>
